import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Seo from '../components/seo';
import Layout from '../components/layout';

const Main = styled.main`
  padding: 5.875rem 0;
  background: var(--beije);
  border-bottom: 8px solid var(--brown);
  .success {
    font-weight: 700;
    font-size: 2.4rem;
  }
  h1 {
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 2rem;
  }
  img {
    margin-bottom: 0px;
    height: 16px;
  }
`;

const ThankYouPage = () => {
  return (
    <Layout>
      <Seo title="Obrigado!" />
      <Main>
        <div className="container">
          <h1 className="success">Mensagem recebida!</h1>
          <p>
            Obrigado pelo interesse. Entraremos em contacto assim que possível.
          </p>
          <p>
            <Link to="/">Voltar à Homepage</Link>
          </p>
        </div>
      </Main>
    </Layout>
  );
};

export default ThankYouPage;
